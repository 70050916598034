import { AccessRules, MiniAppPermission } from '../tasks/MiniApp';
import { AppReleaseMetadata } from './AppReleaseMetadata';

export const DEVELOPMENT_ENVIRONMENT_ID = 'Development';

export interface EnvironmentVariableValue {
  id: string;
  value: string;
}

export interface Environment {
  id: string;
  appId: string;
  name: string;
  releaseId?: string;
  precedingEnvironmentId?: string;
  lastUpdated?: number;
  permissions: MiniAppPermission[];
  variableValues: EnvironmentVariableValue[];
  connections: Record<string, string>;
  matomoSiteId?: number;
  analyticsEnabled: boolean;
  accessRules?: AccessRules;
  maintenance?: AppMaintenance;
}

export interface AppMaintenance {
  state?: AppMaintenanceState;
}

export enum AppMaintenanceState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export interface ReleaseEnvironment {
  environment: Environment;
  release: AppReleaseMetadata;
  state: ReleasePipelineState;
  blockers: ReleaseBlocker[];
}

export interface ReleaseBlocker {
  type: ReleaseBlockerType;
  missingElementName?: string;
  missingElementType?: string;
}

export enum ReleaseBlockerType {
  MISSING_CONNECTION,
  MISSING_ENV_VARIABLE,
  HAS_NEWER_RELEASE
}

export type UnsavedEnvironment = Omit<Environment, 'id' | 'lastUpdated'>;

export interface EnvironmentPipelineStage {
  environment: Environment;
  subsequentStages: EnvironmentPipelineStage[];
}

export enum ReleasePipelineState {
  DEPLOYED,
  READY_TO_DEPLOY,
  DEPLOYMENT_BLOCKED,
  NOT_YET_READY_TO_DEPLOY
}

export interface ReleaseEnvironmentPipelineStage {
  releaseEnvironment: ReleaseEnvironment;
  subsequentStages: ReleaseEnvironmentPipelineStage[];
}

export function findPrecedingStage(environmentId: string, pipeline: EnvironmentPipelineStage):
  EnvironmentPipelineStage | undefined {
  for (const subsequentStage of pipeline.subsequentStages) {
    if (subsequentStage.environment.id === environmentId) {
      return pipeline;
    }
    const precedingStage = findPrecedingStage(environmentId, subsequentStage);
    if (precedingStage) {
      return precedingStage;
    }
  }
  return undefined;
}

export const PIECE_NAMES: Record<string, string> = {
  '@activepieces/piece-activecampaign': 'activecampaign',
  '@activepieces/piece-activepieces': 'activepieces',
  '@activepieces/piece-activity': 'activity',
  '@activepieces/piece-acumbamail': 'acumbamail',
  '@activepieces/piece-afforai': 'afforai',
  '@activepieces/piece-airtable': 'airtable',
  '@activepieces/piece-apitable': 'apitable',
  '@activepieces/piece-amazon-s3': 'amazon-s3',
  '@activepieces/piece-claude': 'claude',
  '@activepieces/piece-approval': 'approval',
  '@activepieces/piece-asana': 'asana',
  '@activepieces/piece-azure-openai': 'azure-openai',
  '@activepieces/piece-bannerbear': 'bannerbear',
  '@activepieces/piece-baserow': 'baserow',
  '@activepieces/piece-beamer': 'beamer',
  '@activepieces/piece-bettermode': 'bettermode',
  '@activepieces/piece-binance': 'binance',
  '@activepieces/piece-blackbaud': 'blackbaud',
  '@activepieces/piece-bloomerang': 'bloomerang',
  '@activepieces/piece-bonjoro': 'bonjoro',
  '@activepieces/piece-box': 'box',
  '@activepieces/piece-sendinblue': 'sendinblue',
  '@activepieces/piece-brilliant-directories': 'brilliant directories',
  '@activepieces/piece-bubble': 'bubble',
  '@activepieces/piece-cal-com': 'cal-com',
  '@activepieces/piece-calendly': 'calendly',
  '@activepieces/piece-cartloom': 'cartloom',
  '@activepieces/piece-certopus': 'certopus',
  '@activepieces/piece-clarifai': 'clarifai',
  '@activepieces/piece-clearout': 'clearout',
  '@activepieces/piece-clickup': 'clickup',
  '@activepieces/piece-clockodo': 'clockodo',
  '@activepieces/piece-connections': 'connections',
  '@activepieces/piece-constant-contact': 'constant contact',
  '@activepieces/piece-contentful': 'contentful',
  '@activepieces/piece-contiguity': 'contiguity',
  '@activepieces/piece-convertkit': 'convertkit',
  '@activepieces/piece-crypto': 'crypto',
  '@activepieces/piece-csv': 'csv',
  '@activepieces/piece-data-mapper': 'data mapper',
  '@activepieces/piece-date-helper': 'date helper',
  '@activepieces/piece-deepl': 'deepl',
  '@activepieces/piece-delay': 'delay',
  '@activepieces/piece-discord': 'discord',
  '@activepieces/piece-discourse': 'discourse',
  '@activepieces/piece-drip': 'drip',
  '@activepieces/piece-dropbox': 'dropbox',
  '@activepieces/piece-dust': 'dust',
  '@activepieces/piece-elevenlabs': 'elevenlabs',
  '@activepieces/piece-facebook-leads': 'facebook leads',
  '@activepieces/piece-facebook-pages': 'facebook pages',
  '@activepieces/piece-figma': 'figma',
  '@activepieces/piece-file-helper': 'file helper',
  '@activepieces/piece-flowise': 'flowise',
  '@activepieces/piece-flowlu': 'flowlu',
  '@activepieces/piece-formbricks': 'formbricks',
  '@activepieces/piece-forms': 'forms',
  '@activepieces/piece-frame': 'frame',
  '@activepieces/piece-freshdesk': 'freshdesk',
  '@activepieces/piece-freshsales': 'freshsales',
  '@activepieces/piece-gcloud-pubsub': 'gcloud pubsub',
  '@activepieces/piece-generatebanners': 'generatebanners',
  '@activepieces/piece-ghostcms': 'ghostcms',
  '@activepieces/piece-github': 'github',
  '@activepieces/piece-gitlab': 'gitlab',
  '@activepieces/piece-gmail': 'gmail',
  '@activepieces/piece-google-calendar': 'google calendar',
  '@activepieces/piece-google-contacts': 'google contacts',
  '@activepieces/piece-google-docs': 'google docs',
  '@activepieces/piece-google-drive': 'google drive',
  '@activepieces/piece-google-forms': 'google forms',
  '@activepieces/piece-google-gemini': 'google gemini',
  '@activepieces/piece-google-my-business': 'google my business',
  '@activepieces/piece-google-sheets': 'google sheets',
  '@activepieces/piece-google-tasks': 'google tasks',
  '@activepieces/piece-gotify': 'gotify',
  '@activepieces/piece-gravityforms': 'gravityforms',
  '@activepieces/piece-hackernews': 'hackernews',
  '@activepieces/piece-heartbeat': 'heartbeat',
  '@activepieces/piece-http': 'http',
  '@activepieces/piece-hubspot': 'hubspot',
  '@activepieces/piece-image-helper': 'image helper',
  '@activepieces/piece-imap': 'imap',
  '@activepieces/piece-instagram-business': 'instagram business',
  '@activepieces/piece-intercom': 'intercom',
  '@activepieces/piece-invoiceninja': 'invoiceninja',
  '@activepieces/piece-jira-cloud': 'jira cloud',
  '@activepieces/piece-jotform': 'jotform',
  '@activepieces/piece-json': 'json',
  '@activepieces/piece-kimai': 'kimai',
  '@activepieces/piece-kizeo-forms': 'kizeo forms',
  '@activepieces/piece-lead-connector': 'lead connector',
  '@activepieces/piece-lever': 'lever',
  '@activepieces/piece-line': 'line',
  '@activepieces/piece-linear': 'linear',
  '@activepieces/piece-linkedin': 'linkedin',
  '@activepieces/piece-llmrails': 'llmrails',
  '@activepieces/piece-localai': 'localai',
  '@activepieces/piece-mailchimp': 'mailchimp',
  '@activepieces/piece-mailer-lite': 'mailer lite',
  '@activepieces/piece-mastodon': 'mastodon',
  '@activepieces/piece-math-helper': 'math helper',
  '@activepieces/piece-matomo': 'matomo',
  '@activepieces/piece-matrix': 'matrix',
  '@activepieces/piece-mattermost': 'mattermost',
  '@activepieces/piece-mautic': 'mautic',
  '@activepieces/piece-metabase': 'metabase',
  '@activepieces/piece-microsoft-excel-365': 'microsoft excel 365',
  '@activepieces/piece-microsoft-onedrive': 'microsoft onedrive',
  '@activepieces/piece-microsoft-teams': 'microsoft teams',
  '@activepieces/piece-mindee': 'mindee',
  '@activepieces/piece-mixpanel': 'mixpanel',
  '@activepieces/piece-monday': 'monday',
  '@activepieces/piece-moxie-crm': 'moxie crm',
  '@activepieces/piece-mysql': 'mysql',
  '@activepieces/piece-nifty': 'nifty',
  '@activepieces/piece-nocodb': 'nocodb',
  '@activepieces/piece-notion': 'notion',
  '@activepieces/piece-ntfy': 'ntfy',
  '@activepieces/piece-odoo': 'odoo',
  '@activepieces/piece-onfleet': 'onfleet',
  '@activepieces/piece-openai': 'openai',
  '@activepieces/piece-open-router': 'open router',
  '@activepieces/piece-pastebin': 'pastebin',
  '@activepieces/piece-pastefy': 'pastefy',
  '@activepieces/piece-pdf': 'pdf',
  '@activepieces/piece-pipedrive': 'pipedrive',
  '@activepieces/piece-postgres': 'postgres',
  '@activepieces/piece-posthog': 'posthog',
  '@activepieces/piece-pushover': 'pushover',
  '@activepieces/piece-qdrant': 'qdrant',
  '@activepieces/piece-quickzu': 'quickzu',
  '@activepieces/piece-retune': 'retune',
  '@activepieces/piece-resend': 'resend',
  '@activepieces/piece-retable': 'retable',
  '@activepieces/piece-robolly': 'robolly',
  '@activepieces/piece-rss': 'rss',
  '@activepieces/piece-saastic': 'saastic',
  '@activepieces/piece-salesforce': 'salesforce',
  '@activepieces/piece-salsa': 'salsa',
  '@activepieces/piece-schedule': 'schedule',
  '@activepieces/piece-sendfox': 'sendfox',
  '@activepieces/piece-sendgrid': 'sendgrid',
  '@activepieces/piece-sendy': 'sendy',
  '@activepieces/piece-sessions-us': 'sessions us',
  '@activepieces/piece-sftp': 'sftp',
  '@activepieces/piece-shopify': 'shopify',
  '@activepieces/piece-simplepdf': 'simplepdf',
  '@activepieces/piece-slack': 'slack',
  '@activepieces/piece-smtp': 'smtp',
  '@activepieces/piece-snowflake': 'snowflake',
  '@activepieces/piece-soap': 'soap',
  '@activepieces/piece-spotify': 'spotify',
  '@activepieces/piece-square': 'square',
  '@activepieces/piece-stability-ai': 'stability ai',
  '@activepieces/piece-stable-diffusion-webui': 'stable diffusion webui',
  '@activepieces/piece-store': 'store',
  '@activepieces/piece-stripe': 'stripe',
  '@activepieces/piece-supabase': 'supabase',
  '@activepieces/piece-surveymonkey': 'surveymonkey',
  '@activepieces/piece-tags': 'tags',
  '@activepieces/piece-talkable': 'talkable',
  '@activepieces/piece-tally': 'tally',
  '@activepieces/piece-telegram-bot': 'telegram bot',
  '@activepieces/piece-text-helper': 'text helper',
  '@activepieces/piece-tidycal': 'tidycal',
  '@activepieces/piece-todoist': 'todoist',
  '@activepieces/piece-totalcms': 'totalcms',
  '@activepieces/piece-trello': 'trello',
  '@activepieces/piece-twilio': 'twilio',
  '@activepieces/piece-twitter': 'twitter',
  '@activepieces/piece-typeform': 'typeform',
  '@activepieces/piece-vbout': 'vbout',
  '@activepieces/piece-vtex': 'vtex',
  '@activepieces/piece-vtiger': 'vtiger',
  '@netlabsrl/piece-wachat': 'wachat',
  '@activepieces/piece-webflow': 'webflow',
  '@activepieces/piece-webhook': 'webhook',
  '@activepieces/piece-wedof': 'wedof',
  '@activepieces/piece-whatsable': 'whatsable',
  '@activepieces/piece-woocommerce': 'woocommerce',
  '@activepieces/piece-wootric': 'wootric',
  '@activepieces/piece-wordpress': 'wordpress',
  '@activepieces/piece-xero': 'xero',
  '@activepieces/piece-xml': 'xml',
  '@activepieces/piece-youtube': 'youtube',
  '@activepieces/piece-zendesk': 'zendesk',
  '@activepieces/piece-zoho-crm': 'zoho crm',
  '@activepieces/piece-zoho-invoice': 'zoho invoice',
  '@activepieces/piece-zoom': 'zoom',
};
